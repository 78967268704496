@import "header";
@import "breadcrumbs";
@import "footer";
@import "fields";
@import "contacts-panel";

body {
  font-family: $font-fmOT3-medium;
  font-size: 14px;
  min-width: 1345px;
  background-color: $page-other-background;
  position: relative;
}

.container.modified {
  width: $page-other-width;
}

.bordered {
  border: 5px solid #77644A;
  color: #77644A;
  padding: 0 8px;
  font-size: 3em;
  font-weight: bold;
}

.main-block {
  padding: 40px 0;
  text-align: center;
  width: $page-other-width;
  line-height: 1;

  & > .top-yellow {
    min-height: 750px;
  }
}

.simple-slider.logos-line {
  .item-icon {
    width: 180px;
  }
}

.num_input_text {
  text-align: center;
  width: 40px;
  height: 30px;
}

.left-quick-menu, .right-quick-menu {
  top: 250px;
}

@media all and (min-width: 1500px)  {
  .wide-block {
    margin-left: -135px;
    margin-right: -135px;
  }
}

/* Employees -- START */
$employee-width: 351px;
$employee-side-padding: 7px;

.employee {
  width: $employee-width;
  padding: 0;
  margin: 9px $employee-side-padding;

  &.first {
    margin-left: 0;
  }

  &.last {
    margin-right: 0;
  }

  .photo {
    background-color: #e7e4df;
    width: 100%;
    height: $employee-width;

    img {
      height: 100%;
      width: 100%;
    }
  }
}

.employees-row-3 {
  width: $employee-width + $employee-side-padding * 2 + $employee-width + $employee-side-padding * 2 + $employee-width;
}

.employees-row-2 {
  width: $employee-width + $employee-side-padding * 2 + $employee-width;
}

.employees-row-1 {
  width: $employee-width;
}

/* Employees -- END */
.top-yellow {
  a:hover {
    color: $color-yellow;
  }

  .categories-row {
    margin-bottom: 40px;
  }
}

.right-quick-menu.quick-menu {
  .menu-item {
    background: #FFFFFF;

    .item-text > a {
      color: $color-brown;
    }

    .item-icon {
      background-color: $color-brown;
    }
  }
}

/* Project and clients -- BEGIN */
.white-block {
  @extend .top-yellow;
  border-top: 0;
}

.wide-block .white-block {
  margin-bottom: 100px;
  padding-left: 110px;
  padding-right: 110px;
}

.multi-block .white-block {
  margin-bottom: 0;
  border-bottom: 0;
}

$project-height: 300px;
$project-pagination-height: 50px;
$project-width: 400px;

.projects-block {
  //height: $project-height * 3 + $project-pagination-height;
  height: auto;
  padding: 0 25px;
  color: $color-text;
  position: relative;

  .white-block {
    .slider-items-container {
      float: none;
    }
  }

  .project {
    height: $project-height;
    width: $project-width;
  }

  .left-arrow, .right-arrow {
    //margin-top: ($project-height * 3 + $project-pagination-height) / 2;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
  }

  .left-arrow {
    left: 0;
  }
  .right-arrow {
    right: 0;
  }

  .slider-items-container {
    //width: $project-width * 3 + 80*2 + 6*2 + 25*2;
    padding: 30px 10px 20px;
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 0;
  }

  .slider-items-wrapper {
    //height: $project-height * 3 + $project-pagination-height;
    height: auto;
    position: relative;
  }

  .slider-items {
    height: $project-height * 3;
  }

  .section-title {
    padding-right: 20px;
    padding-left: 40px;
  }

  .section-text {
    padding-right: 20px;
    padding-left: 40px;

    .title {
      height: 105px;
      font-size: 1.5em;
      margin-bottom: 10px;
      font-family: $font-fmOT3-bold;
      color: $color-header;
    }

    .body {
      height: 100px;
      margin: 10px 0 20px;
      font-size: 11pt;
      line-height: 20pt;
      color: $color-text;
    }
  }

  .pagenum {
    position: absolute;
    right: 20px;
    bottom: 0;
  }
}

@media all and (max-width: 1500px) {
  .wide-block .projects-block {
    padding: 0 85px;
  }
}

.clients-line {
  padding: 0 25px;

  .left-arrow, .right-arrow {
    margin-top: 206px;
  }

  .slider-items-container {
    width: 1430px;
    padding: 5px 0;
    margin-right: 5px;
    margin-bottom: 125px;
  }

  .slider-items-wrapper {
    height: 460px;
  }

  .slider-items {
    height: 460px;
  }

  .slider-item {
    width: 350px;
    margin: 0 5px;
    height: 460px;


    .logo {
      height: 245px;
      width: 100%;
    }

    .text-block {
      padding-top: 10px;
    }

    .title {
      font-size: 1.5em;
      margin-bottom: 17px;
      font-family: $font-fmOT3-bold;
      color: $color-header;

      a {
        text-decoration: none;
        color: $color-header;

        &:hover {
          text-decoration: none;
          color: $color-header;
        }
      }
    }

    .body {
      height: 72px;
      margin: 15px 0 20px;
      font-size: 10pt;
      line-height: 18pt;
      color: $color-text;
    }
  }
}

.logos-line {
  padding: 0 25px;
  height: 80px;
  border-radius: 0;
  margin: 70px auto;
  font-size: 1.1em;

  .slider-items-container {
    width: 1430px;
    padding: 5px 20px;
    margin-right: 5px;
    margin-bottom: 125px;

    .slider-item {
      width: 185px;
    }

    .item-name {
      text-align: left;
      font-family: $font-fmOT3-bold;
      font-size: 1.5em;
      color: $color-header;
    }
  }
}

@media all and (max-width: 1500px) {
  .logos-line {
    .slider-items-container {
      width: 1150px;
    }
  }
}

/* Project and clients -- END */
/* News -- BEGIN */
.top-yellow.news-elem {
  .title {
    color: $color-yellow;
    font-size: 13pt;
    line-height: 21pt;
    font-family: $font-fmOT3-bold;

    a, a:hover {
      color: inherit;
      text-decoration: none;
    }
  }
}

.pagination {
  & > * {
    margin: 5px;
  }

  a:hover {
    color: $color-yellow;
  }
}
/* News -- END */
/* Geography -- BEGIN */
.geography {
  .big-yellow {
    color: $color-yellow;
    font-family: $font-fmOT3-ex-bold;
    font-size: 3em;
    width: 510px;
    margin: 0 auto;
  }

  .subtitle {
    color: $color-header;
    font-family: $font-fmOT3-regular;
    font-size: 1.2em;
  }

  #map {
    top: -50px;
    position: relative;

    path {
      cursor: pointer;
    }
  }
}
/* Geography -- END */