//$base-path: "/personal/themes/main/assets/";
$base-path: "../";

$font-stack: "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-font-size: 14px;
$color-yellow: #FFC432;
$color-header: #5c513d;
$color-brown: #77644A;
$color-text: #95806b;
$color-links: #40A4D3;
$page-width: 1080px;
$footer-height: 216px;

$page-other-width: 1294px;
$page-other-background: #f7f7f8;

/* ICOMOON */
$icon-service-inner-examples: "\e621";
$icon-title-news: "\e622";
$icon-title-vote: "\e623";
$icon-service-inner-order: "\e624";
$icon-wallet: "\e607";
$icon-earphone: "\e608";
$icon-envelope: "\e609";
$icon-pdf: "\e60a";
$icon-personal: "\e60b";
$icon-logout: "\e60c";
$icon-profile: "\e60d";
$icon-research: "\e60e";
$icon-results: "\e60f";
$icon-search-len: "\e610";
$icon-service-adverticing: "\e611";
$icon-service-analytics: "\e612";
$icon-service-call-center: "\e613";
$icon-service-calc-volume: "\e614";
$icon-service-concurrent-analytics: "\e615";
$icon-service-customers-behavior: "\e616";
$icon-service-express-research: "\e617";
$icon-service-grade: "\e618";
$icon-service-inner-description: "\e619";
$icon-service-inner-pack: "\e61a";
$icon-service-inner-methods: "\e61b";
$icon-service-inner-price: "\e61c";
$icon-service-inner-clock: "\e61d";
$icon-service-inner-size-and-time: "\e61e";
$icon-service-to-marketologs: "\e61f";
$icon-title-calc: "\e600";
$icon-title-clients: "\e601";
$icon-title-employees: "\e602";
$icon-title-geography: "\e603";
$icon-title-personal: "\e604";
$icon-title-projects: "\e605";
$icon-title-service: "\e606";
$icon-web: "\e620";
