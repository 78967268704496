@charset "UTF-8";
@import url(http://fonts.googleapis.com/css?family=PT+Sans+Caption&subset=cyrillic,cyrillic-ext,latin,latin-ext);
/* ICOMOON */
@font-face {
  font-family: 'FoundryMonolineOT3-ExtraBold';
  src: url('../fonts/Foundry Monoline OT3/FoundryMonolineOT3-ExtraBold.eot?#iefix') format('embedded-opentype'), url('../fonts/Foundry Monoline OT3/FoundryMonolineOT3-ExtraBold.otf') format('opentype'), url('../fonts/Foundry Monoline OT3/FoundryMonolineOT3-ExtraBold.woff') format('woff'), url('../fonts/Foundry Monoline OT3/FoundryMonolineOT3-ExtraBold.ttf') format('truetype'), url('../fonts/Foundry Monoline OT3/FoundryMonolineOT3-ExtraBold.svg#FoundryMonolineOT3-ExtraBold') format('svg');
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'FoundryMonolineOT3-Bold';
  src: url('../fonts/Foundry Monoline OT3/FoundryMonolineOT3-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/Foundry Monoline OT3/FoundryMonolineOT3-Bold.otf') format('opentype'), url('../fonts/Foundry Monoline OT3/FoundryMonolineOT3-Bold.woff') format('woff'), url('../fonts/Foundry Monoline OT3/FoundryMonolineOT3-Bold.ttf') format('truetype'), url('../fonts/Foundry Monoline OT3/FoundryMonolineOT3-Bold.svg#FoundryMonolineOT3-Bold') format('svg');
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'FoundryMonolineOT3-Medium';
  src: url('../fonts/Foundry Monoline OT3/FoundryMonolineOT3-Medium.eot?#iefix') format('embedded-opentype'), url('../fonts/Foundry Monoline OT3/FoundryMonolineOT3-Medium.otf') format('opentype'), url('../fonts/Foundry Monoline OT3/FoundryMonolineOT3-Medium.woff') format('woff'), url('../fonts/Foundry Monoline OT3/FoundryMonolineOT3-Medium.ttf') format('truetype'), url('../fonts/Foundry Monoline OT3/FoundryMonolineOT3-Medium.svg#FoundryMonolineOT3-Medium') format('svg');
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'FoundryMonolineOT3-Regular';
  src: url('../fonts/Foundry Monoline OT3/FoundryMonolineOT3-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/Foundry Monoline OT3/FoundryMonolineOT3-Regular.otf') format('opentype'), url('../fonts/Foundry Monoline OT3/FoundryMonolineOT3-Regular.woff') format('woff'), url('../fonts/Foundry Monoline OT3/FoundryMonolineOT3-Regular.ttf') format('truetype'), url('../fonts/Foundry Monoline OT3/FoundryMonolineOT3-Regular.svg#FoundryMonolineOT3-Regular') format('svg');
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'GothamPro';
  src: url('../fonts/GothamPro/GothamPro.eot?#iefix') format('embedded-opentype'), url('../fonts/GothamPro/GothamPro.otf') format('opentype'), url('../fonts/GothamPro/GothamPro.woff') format('woff'), url('../fonts/GothamPro/GothamPro.ttf') format('truetype'), url('../fonts/GothamPro/GothamPro.svg#GothamPro') format('svg');
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'GothamProNarrow-Medium';
  src: url('../fonts/GothamPro/GothamProNarrow-Medium.eot?#iefix') format('embedded-opentype'), url('../fonts/GothamPro/GothamProNarrow-Medium.otf') format('opentype'), url('../fonts/GothamPro/GothamProNarrow-Medium.woff') format('woff'), url('../fonts/GothamPro/GothamProNarrow-Medium.ttf') format('truetype'), url('../fonts/GothamPro/GothamProNarrow-Medium.svg#GothamProNarrow-Medium') format('svg');
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'GothamProNarrow-Bold';
  src: url('../fonts/GothamPro/GothamProNarrow-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/GothamPro/GothamProNarrow-Bold.otf') format('opentype'), url('../fonts/GothamPro/GothamProNarrow-Bold.woff') format('woff'), url('../fonts/GothamPro/GothamProNarrow-Bold.ttf') format('truetype'), url('../fonts/GothamPro/GothamProNarrow-Bold.svg#GothamProNarrow-Bold') format('svg');
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'GothamPro-MediumItalic';
  src: url('../fonts/GothamPro/GothamPro-MediumItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/GothamPro/GothamPro-MediumItalic.otf') format('opentype'), url('../fonts/GothamPro/GothamPro-MediumItalic.woff') format('woff'), url('../fonts/GothamPro/GothamPro-MediumItalic.ttf') format('truetype'), url('../fonts/GothamPro/GothamPro-MediumItalic.svg#GothamPro-MediumItalic') format('svg');
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'GothamPro-Medium';
  src: url('../fonts/GothamPro/GothamPro-Medium.eot?#iefix') format('embedded-opentype'), url('../fonts/GothamPro/GothamPro-Medium.otf') format('opentype'), url('../fonts/GothamPro/GothamPro-Medium.woff') format('woff'), url('../fonts/GothamPro/GothamPro-Medium.ttf') format('truetype'), url('../fonts/GothamPro/GothamPro-Medium.svg#GothamPro-Medium') format('svg');
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'GothamPro-LightItalic';
  src: url('../fonts/GothamPro/GothamPro-LightItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/GothamPro/GothamPro-LightItalic.otf') format('opentype'), url('../fonts/GothamPro/GothamPro-LightItalic.woff') format('woff'), url('../fonts/GothamPro/GothamPro-LightItalic.ttf') format('truetype'), url('../fonts/GothamPro/GothamPro-LightItalic.svg#GothamPro-LightItalic') format('svg');
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'GothamPro-Light';
  src: url('../fonts/GothamPro/GothamPro-Light.eot?#iefix') format('embedded-opentype'), url('../fonts/GothamPro/GothamPro-Light.otf') format('opentype'), url('../fonts/GothamPro/GothamPro-Light.woff') format('woff'), url('../fonts/GothamPro/GothamPro-Light.ttf') format('truetype'), url('../fonts/GothamPro/GothamPro-Light.svg#GothamPro-Light') format('svg');
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'GothamPro-Italic';
  src: url('../fonts/GothamPro/GothamPro-Italic.eot?#iefix') format('embedded-opentype'), url('../fonts/GothamPro/GothamPro-Italic.otf') format('opentype'), url('../fonts/GothamPro/GothamPro-Italic.woff') format('woff'), url('../fonts/GothamPro/GothamPro-Italic.ttf') format('truetype'), url('../fonts/GothamPro/GothamPro-Italic.svg#GothamPro-Italic') format('svg');
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'GothamPro-BoldItalic';
  src: url('../fonts/GothamPro/GothamPro-BoldItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/GothamPro/GothamPro-BoldItalic.otf') format('opentype'), url('../fonts/GothamPro/GothamPro-BoldItalic.woff') format('woff'), url('../fonts/GothamPro/GothamPro-BoldItalic.ttf') format('truetype'), url('../fonts/GothamPro/GothamPro-BoldItalic.svg#GothamPro-BoldItalic') format('svg');
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'GothamPro-Bold';
  src: url('../fonts/GothamPro/GothamPro-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/GothamPro/GothamPro-Bold.otf') format('opentype'), url('../fonts/GothamPro/GothamPro-Bold.woff') format('woff'), url('../fonts/GothamPro/GothamPro-Bold.ttf') format('truetype'), url('../fonts/GothamPro/GothamPro-Bold.svg#GothamPro-Bold') format('svg');
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'GothamPro-BlackItalic';
  src: url('../fonts/GothamPro/GothamPro-BlackItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/GothamPro/GothamPro-BlackItalic.otf') format('opentype'), url('../fonts/GothamPro/GothamPro-BlackItalic.woff') format('woff'), url('../fonts/GothamPro/GothamPro-BlackItalic.ttf') format('truetype'), url('../fonts/GothamPro/GothamPro-BlackItalic.svg#GothamPro-BlackItalic') format('svg');
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'GothamPro-Black';
  src: url('../fonts/GothamPro/GothamPro-Black.eot?#iefix') format('embedded-opentype'), url('../fonts/GothamPro/GothamPro-Black.otf') format('opentype'), url('../fonts/GothamPro/GothamPro-Black.woff') format('woff'), url('../fonts/GothamPro/GothamPro-Black.ttf') format('truetype'), url('../fonts/GothamPro/GothamPro-Black.svg#GothamPro-Black') format('svg');
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon/icomoon.eot');
  src: url('../fonts/icomoon/icomoon.eot#iefix') format('embedded-opentype'), url('../fonts/icomoon/icomoon.ttf') format('truetype'), url('../fonts/icomoon/icomoon.woff') format('woff'), url('../fonts/icomoon/icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal; }

.icon {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-service-inner-examples:before {
  content: "\e621"; }

.icon-title-news:before {
  content: "\e622"; }

.icon-title-vote:before {
  content: "\e623"; }

.icon-service-inner-order:before {
  content: "\e624"; }

.icon-wallet:before {
  content: "\e607"; }

.icon-earphone:before {
  content: "\e608"; }

.icon-envelope:before {
  content: "\e609"; }

.icon-pdf:before {
  content: "\e60a"; }

.icon-personal:before {
  content: "\e60b"; }

.icon-logout:before {
  content: "\e60c"; }

.icon-profile:before {
  content: "\e60d"; }

.icon-research:before {
  content: "\e60e"; }

.icon-results:before {
  content: "\e60f"; }

.icon-search-len:before {
  content: "\e610"; }

.icon-service-adverticing:before {
  content: "\e611"; }

.icon-service-analytics:before {
  content: "\e612"; }

.icon-service-call-center:before {
  content: "\e613"; }

.icon-service-calc-volume:before {
  content: "\e614"; }

.icon-service-concurrent-analytics:before {
  content: "\e615"; }

.icon-service-customers-behavior:before {
  content: "\e616"; }

.icon-service-express-research:before {
  content: "\e617"; }

.icon-service-grade:before {
  content: "\e618"; }

.icon-service-inner-description:before {
  content: "\e619"; }

.icon-service-inner-pack:before {
  content: "\e61a"; }

.icon-service-inner-methods:before {
  content: "\e61b"; }

.icon-service-inner-price:before {
  content: "\e61c"; }

.icon-service-inner-clock:before {
  content: "\e61d"; }

.icon-service-inner-size-and-time:before {
  content: "\e61e"; }

.icon-service-to-marketologs:before {
  content: "\e61f"; }

.icon-title-calc:before {
  content: "\e600"; }

.icon-title-clients:before {
  content: "\e601"; }

.icon-title-employees:before {
  content: "\e602"; }

.icon-title-geography:before {
  content: "\e603"; }

.icon-title-personal:before {
  content: "\e604"; }

.icon-title-projects:before {
  content: "\e605"; }

.icon-title-service:before {
  content: "\e606"; }

.icon-web:before {
  content: "\e620"; }

.yellow-text {
  color: #FFC432; }

.center {
  margin: 0 auto;
  text-align: center; }

.text-left {
  text-align: left; }

h1, h2, h3, h4, h5, h6 {
  font-size: 17pt;
  margin-bottom: 17px;
  font-family: 'FoundryMonolineOT3-Bold', "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #5c513d; }
  h1.title, h2.title, h3.title, h4.title, h5.title, h6.title {
    margin-top: 0; }

.title-row h1.title {
  font-family: 'FoundryMonolineOT3-Medium', "Helvetica Neue", Helvetica, Arial, sans-serif; }

a {
  cursor: pointer;
  color: #40A4D3;
  text-decoration: underline; }
  a:hover {
    text-decoration: none; }

ol {
  list-style-type: none;
  counter-reset: item;
  padding: 0; }
  ol li:before {
    color: #5c513d;
    font-weight: bold;
    content: counter(item) ') ';
    counter-increment: item; }

.text-block {
  padding: 27px 30px 45px;
  text-align: left;
  font-size: 11pt;
  line-height: 23pt;
  font-family: 'PT Sans Caption', sans-serif; }

.btn {
  border-radius: 0;
  border: 0;
  margin-right: 12px;
  padding: 8px 12px; }
  .btn:focus {
    border: 0;
    border-radius: 0; }
  .btn:hover {
    color: #FFFFFF; }

.button-main {
  background-color: #FFC432;
  color: #FFFFFF; }

.button-additional {
  background-color: #938169;
  color: #FFFFFF; }

label {
  font-family: 'FoundryMonolineOT3-Regular', "Helvetica Neue", Helvetica, Arial, sans-serif; }

input {
  border-radius: 0; }

form .errors {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25), 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25), 0 1px 2px rgba(0, 0, 0, 0.05);
  background-image: -webkit-linear-gradient(top, #f2dede 0, #e7c3c3 100%);
  background-image: -o-linear-gradient(top, #f2dede 0, #e7c3c3 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f2dede), to(#e7c3c3));
  background-image: linear-gradient(to bottom, #f2dede 0, #e7c3c3 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff2dede', endColorstr='#ffe7c3c3', GradientType=0);
  background-repeat: repeat-x;
  border-color: #dca7a7;
  border-radius: 0px;
  margin: 10px 0;
  padding-top: 7px;
  padding-bottom: 7px; }

.alert {
  border-radius: 0px;
  margin: 10px 0;
  padding-top: 7px;
  padding-bottom: 7px; }

.jq-radio {
  margin-right: 12px;
  height: 8px;
  width: 8px;
  background: #FFFFFF url("../img/unchecked-circle.png") no-repeat;
  border: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none; }
  .jq-radio.checked .jq-radio__div {
    background: #FFC432 url("../img/unchecked-circle.png") no-repeat;
    margin: 0;
    border: 0;
    height: 8px;
    width: 8px;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }

.jq-checkbox {
  margin-right: 10px;
  height: 8px;
  width: 8px;
  background: #FFFFFF url("../img/checkbox-unchecked.png") no-repeat;
  border: 0;
  border-radius: 0;
  box-shadow: none; }
  .jq-checkbox.checked {
    background: #FFC432 url("../img/checkbox-unchecked.png") no-repeat;
    height: 8px;
    width: 8px; }
    .jq-checkbox.checked .jq-checkbox__div {
      background: none;
      box-shadow: none; }

.common-block, .top-yellow, .white-block {
  background-color: #FFFFFF;
  margin-bottom: 17px; }
  .white-block .common-block, .white-block .top-yellow, .white-block .white-block {
    background-color: #F0F0F0; }
  .common-block .section-title, .top-yellow .section-title, .white-block .section-title {
    font-size: 2em;
    margin-bottom: 17px;
    font-family: 'FoundryMonolineOT3-Bold', "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #5c513d; }
    .common-block .section-title a, .top-yellow .section-title a, .white-block .section-title a {
      text-decoration: none;
      color: inherit; }
      .common-block .section-title a:hover, .top-yellow .section-title a:hover, .white-block .section-title a:hover {
        text-decoration: none;
        color: inherit; }
  .common-block .section-text, .top-yellow .section-text, .white-block .section-text {
    text-align: left;
    font-family: 'PT Sans Caption', sans-serif;
    color: #77644A; }
    .common-block .section-text .section-text-col, .top-yellow .section-text .section-text-col, .white-block .section-text .section-text-col {
      padding-left: 10px;
      vertical-align: top;
      padding-right: 45px; }
    .common-block .section-text .section-icon-col, .top-yellow .section-text .section-icon-col, .white-block .section-text .section-icon-col {
      vertical-align: top;
      font-size: 50px; }
      .common-block .section-text .section-icon-col .section-icon, .top-yellow .section-text .section-icon-col .section-icon, .white-block .section-text .section-icon-col .section-icon {
        margin-top: -10px; }
    .common-block .section-text .title, .top-yellow .section-text .title, .white-block .section-text .title {
      font-size: 1.5em;
      margin-bottom: 17px;
      font-family: 'FoundryMonolineOT3-Bold', "Helvetica Neue", Helvetica, Arial, sans-serif;
      color: #5c513d; }
      .common-block .section-text .title a, .top-yellow .section-text .title a, .white-block .section-text .title a {
        text-decoration: none;
        color: inherit; }
        .common-block .section-text .title a:hover, .top-yellow .section-text .title a:hover, .white-block .section-text .title a:hover {
          text-decoration: none;
          color: inherit; }
    .common-block .section-text .body, .top-yellow .section-text .body, .white-block .section-text .body {
      margin: 21px 0;
      font-size: 11pt;
      line-height: 23pt; }
      .common-block .section-text .body ul, .top-yellow .section-text .body ul, .white-block .section-text .body ul {
        list-style: none;
        padding-left: 0; }
  .common-block .category-row, .top-yellow .category-row, .white-block .category-row {
    margin-left: 50px;
    margin-bottom: 19px; }
    .common-block .category-row .section-text-col, .top-yellow .category-row .section-text-col, .white-block .category-row .section-text-col {
      padding-top: 15px; }

.top-yellow, .white-block {
  border-top: 5px solid #FFC432;
  border-bottom: 2px solid #ededed;
  border-left: 1px solid #fcfcfc;
  border-right: 1px solid #fcfcfc; }

.additional-links-row .additional-link {
  margin-right: 20px;
  font-family: 'FoundryMonolineOT3-Medium', "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px; }
  .additional-links-row .additional-link .icon {
    margin-right: 10px; }
  .additional-links-row .additional-link a {
    text-decoration: none; }

.title-row {
  margin-bottom: 48px;
  color: #77644A; }
  .title-row .title-icon {
    height: 76px;
    width: 60px;
    margin: 0 auto; }
    .title-row .title-icon .bordered {
      height: 100%;
      padding: 10px 0px; }
      .title-row .title-icon .bordered .icon {
        font-size: 50px; }
  .title-row .title {
    margin-top: 29px;
    text-transform: uppercase;
    font-size: 1.2em; }
    .title-row .title a, .title-row .title a:hover {
      color: inherit;
      text-decoration: none; }

.specialist {
  margin-top: 28px;
  color: #77644A; }
  .specialist .full-name {
    font-family: 'FoundryMonolineOT3-Bold', "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14pt;
    line-height: 19pt;
    color: #5c513d; }
  .specialist .job-position {
    font-size: 10pt;
    line-height: 21pt; }

.top-yellow.news-elem, .news-elem.white-block {
  cursor: pointer; }

@media only screen and (max-width: 800px) {
  .mobile .pop-up-callbackform-wrapper {
    font-size: 20px;
    color: white; } }

.quick-menu {
  border-collapse: separate;
  border-spacing: 0 5px;
  font-family: 'FoundryMonolineOT3-Bold', "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.25em;
  z-index: 10000; }
  .quick-menu a {
    cursor: pointer;
    color: #FFFFFF;
    text-decoration: underline; }
    .quick-menu a:hover {
      text-decoration: none; }
  .quick-menu.dark .menu-item .item-text a {
    color: #4a4b4d; }
  .quick-menu .menu-item {
    height: 55px; }
  .quick-menu .item-icon {
    float: right;
    height: 55px;
    width: 55px;
    background-color: #57585a;
    padding: 7px;
    text-align: center; }
    .quick-menu .item-icon a {
      text-decoration: none; }
    .quick-menu .item-icon .icon {
      font-size: 34px; }
  .quick-menu .item-text {
    text-align: right;
    padding: 0 15px; }

.right-quick-menu {
  position: fixed;
  right: 0;
  top: 150px; }

.left-quick-menu {
  position: fixed;
  left: 0;
  top: 150px;
  border-spacing: 0 0; }
  .left-quick-menu .menu-item {
    height: 48px; }
  .left-quick-menu .item-icon {
    float: right;
    height: 44px;
    width: 48px;
    background-color: #fbf9fc;
    padding: 3px;
    text-align: center; }

.simple-slider .arrow, .simple-slider .left-arrow, .simple-slider .right-arrow {
  height: 37px;
  width: 21px;
  float: left;
  cursor: pointer;
  margin: 10px 0; }
.simple-slider .left-arrow {
  background: url("../img/arrow-left.png"); }
.simple-slider .right-arrow {
  background: url("../img/arrow-right.png"); }
.simple-slider .slider-items-container {
  padding: 5px 50px;
  float: left; }
.simple-slider .slider-items-wrapper {
  overflow: hidden;
  height: 48px; }
.simple-slider .slider-items {
  height: 48px;
  padding: 0;
  position: relative;
  width: 2000px; }
  .simple-slider .slider-items li {
    list-style: none;
    float: left;
    max-width: 100%; }
.simple-slider .slider-item {
  cursor: pointer;
  width: 230px; }
.simple-slider .item-icon {
  height: 45px;
  width: 45px; }
  .simple-slider .item-icon a {
    text-decoration: none; }
.simple-slider .item-name {
  padding: 0 15px; }
  .simple-slider .item-name a {
    text-decoration: none;
    color: #FFFFFF; }
    .simple-slider .item-name a:hover {
      text-decoration: none;
      color: #FFFFFF; }

.categories-navbar, .categories-navbar-dummy {
  height: 120px;
  background-color: #4b4b4d;
  border-radius: 0;
  padding: 30px 134px;
  font-size: 14pt;
  line-height: 14pt;
  width: 100%;
  z-index: 300;
  top: 0; }
  .categories-navbar .slider-items-container, .categories-navbar-dummy .slider-items-container {
    width: 95%;
    min-width: 1000px; }
  .categories-navbar .slider-item, .categories-navbar-dummy .slider-item {
    width: 270px; }
    .categories-navbar .slider-item .item-icon > a, .categories-navbar .slider-item .item-icon > a:hover, .categories-navbar-dummy .slider-item .item-icon > a, .categories-navbar-dummy .slider-item .item-icon > a:hover {
      color: #40A4D3; }
      .categories-navbar .slider-item .item-icon > a > div, .categories-navbar .slider-item .item-icon > a:hover > div, .categories-navbar-dummy .slider-item .item-icon > a > div, .categories-navbar-dummy .slider-item .item-icon > a:hover > div {
        height: 45px;
        width: 45px;
        font-size: 45px;
        line-height: 45px; }
    .categories-navbar .slider-item .item-name a:hover, .categories-navbar-dummy .slider-item .item-name a:hover {
      color: #FFC432; }

.header {
  background: #FFFFFF;
  height: 125px;
  font-family: 'FoundryMonolineOT3-Bold', "Helvetica Neue", Helvetica, Arial, sans-serif;
  border-bottom: 2px solid #ebebeb;
  font-size: 1.15em;
  padding-top: 30px; }
  .header .container.modified {
    width: 1294px; }
  .header .logo {
    background: url("../img/bcg_logo_2013.png") no-repeat;
    height: 73px;
    width: 208px;
    float: left; }
  .header .main-menu {
    margin-top: 10px;
    margin-left: 0; }
  .header #navbar a {
    color: #77644A; }
  .header #navbar li .divider {
    border-left: 1px solid #77644A;
    height: 15px;
    margin-top: 20px; }
  .header #navbar li.active a {
    color: #FFC432; }
  .header #navbar li a {
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px; }
    .header #navbar li a:hover {
      background-color: inherit;
      text-decoration: underline; }
  .header #navbar .languages {
    margin-top: 10px;
    text-transform: uppercase;
    font-family: 'FoundryMonolineOT3-Regular', "Helvetica Neue", Helvetica, Arial, sans-serif; }
    .header #navbar .languages li.active a {
      text-decoration: none; }
    .header #navbar .languages a {
      font-size: 0.8em;
      color: #4a4b4d;
      padding-left: 5px;
      padding-right: 5px;
      text-decoration: underline; }
      .header #navbar .languages a:hover {
        background-color: inherit;
        text-decoration: none; }
  .header #navbar .search-form {
    margin: 20px 0;
    width: 230px; }
    .header #navbar .search-form .input-group-addon {
      border: 2px solid #77644A;
      border-right-width: 0;
      padding: 3px;
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
      background-color: #FFFFFF; }
    .header #navbar .search-form .search-input {
      color: #77644A;
      padding-left: 6px;
      border: 2px solid #77644A;
      border-left-width: 0;
      height: 31px;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
      background-color: #FFFFFF;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none; }
    .header #navbar .search-form .icon-search-len {
      font-weight: bold;
      font-size: 18px;
      color: #77644A; }
    .header #navbar .search-form .search-len {
      height: 20px;
      width: 20px;
      background: url("../img/search_len.png"); }
  .header #navbar .contacts {
    font-family: 'FoundryMonolineOT3-Medium', "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #4a4b4d;
    padding: 5px 0 0 0;
    font-size: 14pt; }
    .header #navbar .contacts a {
      color: #4a4b4d; }
    .header #navbar .contacts .icon {
      font-size: 18px;
      color: #FFC432;
      margin-right: 15px;
      margin-top: 5px; }

.breadcrumb {
  background-color: inherit; }
  .breadcrumb a {
    color: #95806b; }
  .breadcrumb > li + li:before {
    padding: 0 5px;
    color: #95806b;
    content: "»"; }
  .breadcrumb .active {
    color: #95806b; }

.footer {
  background: #666054;
  font-family: 'FoundryMonolineOT3-Regular', "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.15em;
  line-height: 1em;
  color: #fff;
  padding: 33px 0;
  height: 216px; }
  .footer a {
    color: #fff;
    text-decoration: underline;
    padding: 0 5px; }
  .footer .addresses td {
    padding: 0 30px 16px 0; }
  .footer .address {
    float: right; }
    .footer .address .left {
      float: left;
      text-align: right;
      padding-right: 8px;
      border-right: 1px solid #fff; }
    .footer .address .right {
      float: left;
      padding-left: 8px;
      text-align: left; }
    .footer .address .city {
      color: #FFC432;
      font-family: 'FoundryMonolineOT3-Bold', "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 1.25em; }
    .footer .address .city, .footer .address .street {
      margin-bottom: 4px; }
  .footer .copyright {
    margin-top: 30px; }

.left-field {
  width: 300px;
  height: 931px;
  position: absolute;
  z-index: -1;
  left: 0;
  top: 250px; }

.left-field-switch {
  width: 300px;
  height: 369px;
  position: absolute;
  z-index: -1;
  left: 0;
  bottom: 196px;
  background: url("../img/fields/switch_field_l.png") no-repeat; }

.right-field {
  width: 300px;
  height: 1300px;
  position: absolute;
  z-index: -1;
  right: 0;
  top: 250px; }

.advertising-field-left {
  background: url("../img/fields/service_advertising_field_l.png") no-repeat; }

.advertising-field-right {
  background: url("../img/fields/service_advertising_field_r.png") no-repeat; }

.analytics-field-left {
  background: url("../img/fields/service_analytics_field_l.png") no-repeat; }

.analytics-field-right {
  background: url("../img/fields/service_analytics_field_r.png") no-repeat; }

.calc-volume-field-left {
  background: url("../img/fields/service_calc_volume_field_l.png") no-repeat; }

.calc-volume-field-right {
  background: url("../img/fields/service_calc_volume_field_r.png") no-repeat; }

.call-center-field-left {
  background: url("../img/fields/service_call_center_field_l.png") no-repeat; }

.call-center-field-right {
  background: url("../img/fields/service_call_center_field_r.png") no-repeat; }

.concurrent-analytics-field-left {
  background: url("../img/fields/service_concurrent_analytics_field_l.png") no-repeat; }

.concurrent-analytics-field-right {
  background: url("../img/fields/service_concurrent_analytics_field_r.png") no-repeat; }

.customers-behavior-field-left {
  background: url("../img/fields/service_customers_behavior_field_l.png") no-repeat; }

.customers-behavior-field-right {
  background: url("../img/fields/service_customers_behavior_field_r.png") no-repeat; }

.express-research-field-left {
  background: url("../img/fields/service_express_research_field_l.png") no-repeat; }

.express-research-field-right {
  background: url("../img/fields/service_express_research_field_r.png") no-repeat; }

.grade-field-left {
  background: url("../img/fields/service_grade_field_l.png") no-repeat; }

.grade-field-right {
  background: url("../img/fields/service_grade_field_r.png") no-repeat; }

.contacts-panel, .contacts-panel-dummy {
  background-color: #FFC432;
  margin-bottom: 0;
  font-family: 'FoundryMonolineOT3-Medium', "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #4a4b4d;
  font-size: 14pt;
  border-radius: 0;
  width: 100%;
  height: 50px;
  bottom: 0;
  position: fixed;
  z-index: 300;
  display: none;
  padding: 10px 0; }
  .contacts-panel a, .contacts-panel-dummy a {
    color: #4a4b4d; }
  .contacts-panel .icon-research, .contacts-panel-dummy .icon-research {
    font-size: 18px;
    color: #FFFFFF;
    margin-right: 15px;
    margin-top: 2px; }
  .contacts-panel .icon-earphone, .contacts-panel-dummy .icon-earphone {
    font-size: 18px;
    color: #FFFFFF;
    margin-right: 15px;
    margin-top: 2px; }
  .contacts-panel .icon-envelope, .contacts-panel-dummy .icon-envelope {
    font-size: 20px;
    color: #FFFFFF;
    margin-right: 15px;
    margin-top: 5px;
    position: relative;
    top: 3px; }
  .contacts-panel .right, .contacts-panel-dummy .right {
    text-align: right; }

body {
  font-family: 'FoundryMonolineOT3-Medium', "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  min-width: 1345px;
  background-color: #f7f7f8;
  position: relative; }

.container.modified {
  width: 1294px; }

.bordered {
  border: 5px solid #77644A;
  color: #77644A;
  padding: 0 8px;
  font-size: 3em;
  font-weight: bold; }

.main-block {
  padding: 40px 0;
  text-align: center;
  width: 1294px;
  line-height: 1; }
  .main-block > .top-yellow, .main-block > .white-block {
    min-height: 750px; }

.simple-slider.logos-line .item-icon {
  width: 180px; }

.num_input_text {
  text-align: center;
  width: 40px;
  height: 30px; }

.left-quick-menu, .right-quick-menu {
  top: 250px; }

@media all and (min-width: 1500px) {
  .wide-block {
    margin-left: -135px;
    margin-right: -135px; } }

/* Employees -- START */
.employee {
  width: 351px;
  padding: 0;
  margin: 9px 7px; }
  .employee.first {
    margin-left: 0; }
  .employee.last {
    margin-right: 0; }
  .employee .photo {
    background-color: #e7e4df;
    width: 100%;
    height: 351px; }
    .employee .photo img {
      height: 100%;
      width: 100%; }

.employees-row-3 {
  width: 1081px; }

.employees-row-2 {
  width: 716px; }

.employees-row-1 {
  width: 351px; }

/* Employees -- END */
.top-yellow a:hover, .white-block a:hover {
  color: #FFC432; }
.top-yellow .categories-row, .white-block .categories-row {
  margin-bottom: 40px; }

.right-quick-menu.quick-menu .menu-item {
  background: #FFFFFF; }
  .right-quick-menu.quick-menu .menu-item .item-text > a {
    color: #77644A; }
  .right-quick-menu.quick-menu .menu-item .item-icon {
    background-color: #77644A; }

/* Project and clients -- BEGIN */
.white-block {
  border-top: 0; }

.wide-block .white-block {
  margin-bottom: 100px;
  padding-left: 110px;
  padding-right: 110px; }

.multi-block .white-block {
  margin-bottom: 0;
  border-bottom: 0; }

.projects-block {
  height: auto;
  padding: 0 25px;
  color: #95806b;
  position: relative; }
  .projects-block .white-block .slider-items-container {
    float: none; }
  .projects-block .project {
    height: 300px;
    width: 400px; }
  .projects-block .left-arrow, .projects-block .right-arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0; }
  .projects-block .left-arrow {
    left: 0; }
  .projects-block .right-arrow {
    right: 0; }
  .projects-block .slider-items-container {
    padding: 30px 10px 20px;
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 0; }
  .projects-block .slider-items-wrapper {
    height: auto;
    position: relative; }
  .projects-block .slider-items {
    height: 900px; }
  .projects-block .section-title {
    padding-right: 20px;
    padding-left: 40px; }
  .projects-block .section-text {
    padding-right: 20px;
    padding-left: 40px; }
    .projects-block .section-text .title {
      height: 105px;
      font-size: 1.5em;
      margin-bottom: 10px;
      font-family: 'FoundryMonolineOT3-Bold', "Helvetica Neue", Helvetica, Arial, sans-serif;
      color: #5c513d; }
    .projects-block .section-text .body {
      height: 100px;
      margin: 10px 0 20px;
      font-size: 11pt;
      line-height: 20pt;
      color: #95806b; }
  .projects-block .pagenum {
    position: absolute;
    right: 20px;
    bottom: 0; }

@media all and (max-width: 1500px) {
  .wide-block .projects-block {
    padding: 0 85px; } }

.clients-line {
  padding: 0 25px; }
  .clients-line .left-arrow, .clients-line .right-arrow {
    margin-top: 206px; }
  .clients-line .slider-items-container {
    width: 1430px;
    padding: 5px 0;
    margin-right: 5px;
    margin-bottom: 125px; }
  .clients-line .slider-items-wrapper {
    height: 460px; }
  .clients-line .slider-items {
    height: 460px; }
  .clients-line .slider-item {
    width: 350px;
    margin: 0 5px;
    height: 460px; }
    .clients-line .slider-item .logo {
      height: 245px;
      width: 100%; }
    .clients-line .slider-item .text-block {
      padding-top: 10px; }
    .clients-line .slider-item .title {
      font-size: 1.5em;
      margin-bottom: 17px;
      font-family: 'FoundryMonolineOT3-Bold', "Helvetica Neue", Helvetica, Arial, sans-serif;
      color: #5c513d; }
      .clients-line .slider-item .title a {
        text-decoration: none;
        color: #5c513d; }
        .clients-line .slider-item .title a:hover {
          text-decoration: none;
          color: #5c513d; }
    .clients-line .slider-item .body {
      height: 72px;
      margin: 15px 0 20px;
      font-size: 10pt;
      line-height: 18pt;
      color: #95806b; }

.logos-line {
  padding: 0 25px;
  height: 80px;
  border-radius: 0;
  margin: 70px auto;
  font-size: 1.1em; }
  .logos-line .slider-items-container {
    width: 1430px;
    padding: 5px 20px;
    margin-right: 5px;
    margin-bottom: 125px; }
    .logos-line .slider-items-container .slider-item {
      width: 185px; }
    .logos-line .slider-items-container .item-name {
      text-align: left;
      font-family: 'FoundryMonolineOT3-Bold', "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 1.5em;
      color: #5c513d; }

@media all and (max-width: 1500px) {
  .logos-line .slider-items-container {
    width: 1150px; } }

/* Project and clients -- END */
/* News -- BEGIN */
.top-yellow.news-elem .title, .news-elem.white-block .title {
  color: #FFC432;
  font-size: 13pt;
  line-height: 21pt;
  font-family: 'FoundryMonolineOT3-Bold', "Helvetica Neue", Helvetica, Arial, sans-serif; }
  .top-yellow.news-elem .title a, .news-elem.white-block .title a, .top-yellow.news-elem .title a:hover, .news-elem.white-block .title a:hover {
    color: inherit;
    text-decoration: none; }

.pagination > * {
  margin: 5px; }
.pagination a:hover {
  color: #FFC432; }

/* News -- END */
/* Geography -- BEGIN */
.geography .big-yellow {
  color: #FFC432;
  font-family: 'FoundryMonolineOT3-ExtraBold', "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 3em;
  width: 510px;
  margin: 0 auto; }
.geography .subtitle {
  color: #5c513d;
  font-family: 'FoundryMonolineOT3-Regular', "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.2em; }
.geography #map {
  top: -50px;
  position: relative; }
  .geography #map path {
    cursor: pointer; }

/* Geography -- END */
