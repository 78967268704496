@import "fixed-menu";
@import "simple-slider";
@import "categories-navbar";

$height-header: 125px;

.header {
  background: #FFFFFF;
  height: $height-header;
  font-family: $font-fmOT3-bold;
  border-bottom: 2px solid #ebebeb;
  font-size: 1.15em;
  padding-top: 30px;

  .container.modified {
    width: 1294px;
  }

  .logo {
    background: url("#{$base-path}img/bcg_logo_2013.png") no-repeat;
    height: 73px;
    width: 208px;
    float: left;
  }

  .main-menu {
    margin-top: 10px;
    margin-left: 0;
  }

  #navbar {
    a {
      color: $color-brown;
    }

    li {
      .divider {
        border-left: 1px solid $color-brown;
        height: 15px;
        margin-top: 20px;
      }

      &.active {
        a {
          color: $color-yellow;
        }
      }

      a {
        cursor: pointer;
        padding-left: 10px;
        padding-right: 10px;

        &:hover {
          background-color: inherit;
          text-decoration: underline;
        }
      }
    }

    .languages {
      margin-top: 10px;
      text-transform: uppercase;
      font-family: $font-fmOT3-regular;

      li {
        &.active {
          a {
            text-decoration: none;
          }
        }
      }

      a {
        font-size: 0.8em;
        color: #4a4b4d;
        padding-left: 5px;
        padding-right: 5px;
        text-decoration: underline;

        &:hover {
          background-color: inherit;
          text-decoration: none;
        }
      }
    }

    .search-form {
      margin: 20px 0;
      width: 230px;

      .input-group-addon {
        border: 2px solid $color-brown;
        border-right-width: 0;
        padding: 3px;
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
        background-color: #FFFFFF;
      }

      .search-input {
        color: $color-brown;
        padding-left: 6px;
        border: 2px solid $color-brown;
        border-left-width: 0;
        height: 31px;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        background-color: #FFFFFF;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }

      .icon-search-len {
        font-weight: bold;
        font-size: 18px;
        color: $color-brown;
      }

      .search-len {
        height: 20px;
        width: 20px;
        background: url("#{$base-path}img/search_len.png");
      }
    }

    .contacts {
      font-family: $font-fmOT3-medium;
      color: #4a4b4d;
      padding: 5px 0 0 0;
      font-size: 14pt;

      a {
        color: #4a4b4d;
      }

      .icon {
        font-size: 18px;
        color: $color-yellow;
        margin-right: 15px;
        margin-top: 5px;
      }
    }
  }
}