@import url(http://fonts.googleapis.com/css?family=PT+Sans+Caption&subset=cyrillic,cyrillic-ext,latin,latin-ext);

$font-pt-sans-caption: 'PT Sans Caption', sans-serif;

@font-face {
  font-family: 'FoundryMonolineOT3-ExtraBold';
  src: url('#{$base-path}fonts/Foundry Monoline OT3/FoundryMonolineOT3-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('#{$base-path}fonts/Foundry Monoline OT3/FoundryMonolineOT3-ExtraBold.otf')  format('opentype'),
  url('#{$base-path}fonts/Foundry Monoline OT3/FoundryMonolineOT3-ExtraBold.woff') format('woff'),
  url('#{$base-path}fonts/Foundry Monoline OT3/FoundryMonolineOT3-ExtraBold.ttf')  format('truetype'),
  url('#{$base-path}fonts/Foundry Monoline OT3/FoundryMonolineOT3-ExtraBold.svg#FoundryMonolineOT3-ExtraBold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FoundryMonolineOT3-Bold';
  src: url('#{$base-path}fonts/Foundry Monoline OT3/FoundryMonolineOT3-Bold.eot?#iefix') format('embedded-opentype'),
  url('#{$base-path}fonts/Foundry Monoline OT3/FoundryMonolineOT3-Bold.otf')  format('opentype'),
  url('#{$base-path}fonts/Foundry Monoline OT3/FoundryMonolineOT3-Bold.woff') format('woff'),
  url('#{$base-path}fonts/Foundry Monoline OT3/FoundryMonolineOT3-Bold.ttf')  format('truetype'),
  url('#{$base-path}fonts/Foundry Monoline OT3/FoundryMonolineOT3-Bold.svg#FoundryMonolineOT3-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FoundryMonolineOT3-Medium';
  src: url('#{$base-path}fonts/Foundry Monoline OT3/FoundryMonolineOT3-Medium.eot?#iefix') format('embedded-opentype'),
  url('#{$base-path}fonts/Foundry Monoline OT3/FoundryMonolineOT3-Medium.otf')  format('opentype'),
  url('#{$base-path}fonts/Foundry Monoline OT3/FoundryMonolineOT3-Medium.woff') format('woff'),
  url('#{$base-path}fonts/Foundry Monoline OT3/FoundryMonolineOT3-Medium.ttf')  format('truetype'),
  url('#{$base-path}fonts/Foundry Monoline OT3/FoundryMonolineOT3-Medium.svg#FoundryMonolineOT3-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FoundryMonolineOT3-Regular';
  src: url('#{$base-path}fonts/Foundry Monoline OT3/FoundryMonolineOT3-Regular.eot?#iefix') format('embedded-opentype'),
  url('#{$base-path}fonts/Foundry Monoline OT3/FoundryMonolineOT3-Regular.otf')  format('opentype'),
  url('#{$base-path}fonts/Foundry Monoline OT3/FoundryMonolineOT3-Regular.woff') format('woff'),
  url('#{$base-path}fonts/Foundry Monoline OT3/FoundryMonolineOT3-Regular.ttf')  format('truetype'),
  url('#{$base-path}fonts/Foundry Monoline OT3/FoundryMonolineOT3-Regular.svg#FoundryMonolineOT3-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

$font-fmOT3-ex-bold: 'FoundryMonolineOT3-ExtraBold', $font-stack;
$font-fmOT3-bold: 'FoundryMonolineOT3-Bold', $font-stack;
$font-fmOT3-medium: 'FoundryMonolineOT3-Medium', $font-stack;
$font-fmOT3-regular: 'FoundryMonolineOT3-Regular', $font-stack;

@font-face {
  font-family: 'GothamPro';
  src: url('#{$base-path}fonts/GothamPro/GothamPro.eot?#iefix') format('embedded-opentype'),
  url('#{$base-path}fonts/GothamPro/GothamPro.otf')  format('opentype'),
  url('#{$base-path}fonts/GothamPro/GothamPro.woff') format('woff'),
  url('#{$base-path}fonts/GothamPro/GothamPro.ttf')  format('truetype'),
  url('#{$base-path}fonts/GothamPro/GothamPro.svg#GothamPro') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GothamProNarrow-Medium';
  src: url('#{$base-path}fonts/GothamPro/GothamProNarrow-Medium.eot?#iefix') format('embedded-opentype'),  url('#{$base-path}fonts/GothamPro/GothamProNarrow-Medium.otf')  format('opentype'),
  url('#{$base-path}fonts/GothamPro/GothamProNarrow-Medium.woff') format('woff'), url('#{$base-path}fonts/GothamPro/GothamProNarrow-Medium.ttf')  format('truetype'), url('#{$base-path}fonts/GothamPro/GothamProNarrow-Medium.svg#GothamProNarrow-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GothamProNarrow-Bold';
  src: url('#{$base-path}fonts/GothamPro/GothamProNarrow-Bold.eot?#iefix') format('embedded-opentype'),  url('#{$base-path}fonts/GothamPro/GothamProNarrow-Bold.otf')  format('opentype'),
  url('#{$base-path}fonts/GothamPro/GothamProNarrow-Bold.woff') format('woff'), url('#{$base-path}fonts/GothamPro/GothamProNarrow-Bold.ttf')  format('truetype'), url('#{$base-path}fonts/GothamPro/GothamProNarrow-Bold.svg#GothamProNarrow-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GothamPro-MediumItalic';
  src: url('#{$base-path}fonts/GothamPro/GothamPro-MediumItalic.eot?#iefix') format('embedded-opentype'),  url('#{$base-path}fonts/GothamPro/GothamPro-MediumItalic.otf')  format('opentype'),
  url('#{$base-path}fonts/GothamPro/GothamPro-MediumItalic.woff') format('woff'), url('#{$base-path}fonts/GothamPro/GothamPro-MediumItalic.ttf')  format('truetype'), url('#{$base-path}fonts/GothamPro/GothamPro-MediumItalic.svg#GothamPro-MediumItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GothamPro-Medium';
  src: url('#{$base-path}fonts/GothamPro/GothamPro-Medium.eot?#iefix') format('embedded-opentype'),  url('#{$base-path}fonts/GothamPro/GothamPro-Medium.otf')  format('opentype'),
  url('#{$base-path}fonts/GothamPro/GothamPro-Medium.woff') format('woff'), url('#{$base-path}fonts/GothamPro/GothamPro-Medium.ttf')  format('truetype'), url('#{$base-path}fonts/GothamPro/GothamPro-Medium.svg#GothamPro-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GothamPro-LightItalic';
  src: url('#{$base-path}fonts/GothamPro/GothamPro-LightItalic.eot?#iefix') format('embedded-opentype'),  url('#{$base-path}fonts/GothamPro/GothamPro-LightItalic.otf')  format('opentype'),
  url('#{$base-path}fonts/GothamPro/GothamPro-LightItalic.woff') format('woff'), url('#{$base-path}fonts/GothamPro/GothamPro-LightItalic.ttf')  format('truetype'), url('#{$base-path}fonts/GothamPro/GothamPro-LightItalic.svg#GothamPro-LightItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GothamPro-Light';
  src: url('#{$base-path}fonts/GothamPro/GothamPro-Light.eot?#iefix') format('embedded-opentype'),  url('#{$base-path}fonts/GothamPro/GothamPro-Light.otf')  format('opentype'),
  url('#{$base-path}fonts/GothamPro/GothamPro-Light.woff') format('woff'), url('#{$base-path}fonts/GothamPro/GothamPro-Light.ttf')  format('truetype'), url('#{$base-path}fonts/GothamPro/GothamPro-Light.svg#GothamPro-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GothamPro-Italic';
  src: url('#{$base-path}fonts/GothamPro/GothamPro-Italic.eot?#iefix') format('embedded-opentype'),  url('#{$base-path}fonts/GothamPro/GothamPro-Italic.otf')  format('opentype'),
  url('#{$base-path}fonts/GothamPro/GothamPro-Italic.woff') format('woff'), url('#{$base-path}fonts/GothamPro/GothamPro-Italic.ttf')  format('truetype'), url('#{$base-path}fonts/GothamPro/GothamPro-Italic.svg#GothamPro-Italic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GothamPro-BoldItalic';
  src: url('#{$base-path}fonts/GothamPro/GothamPro-BoldItalic.eot?#iefix') format('embedded-opentype'),  url('#{$base-path}fonts/GothamPro/GothamPro-BoldItalic.otf')  format('opentype'),
  url('#{$base-path}fonts/GothamPro/GothamPro-BoldItalic.woff') format('woff'), url('#{$base-path}fonts/GothamPro/GothamPro-BoldItalic.ttf')  format('truetype'), url('#{$base-path}fonts/GothamPro/GothamPro-BoldItalic.svg#GothamPro-BoldItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GothamPro-Bold';
  src: url('#{$base-path}fonts/GothamPro/GothamPro-Bold.eot?#iefix') format('embedded-opentype'),  url('#{$base-path}fonts/GothamPro/GothamPro-Bold.otf')  format('opentype'),
  url('#{$base-path}fonts/GothamPro/GothamPro-Bold.woff') format('woff'), url('#{$base-path}fonts/GothamPro/GothamPro-Bold.ttf')  format('truetype'), url('#{$base-path}fonts/GothamPro/GothamPro-Bold.svg#GothamPro-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GothamPro-BlackItalic';
  src: url('#{$base-path}fonts/GothamPro/GothamPro-BlackItalic.eot?#iefix') format('embedded-opentype'),  url('#{$base-path}fonts/GothamPro/GothamPro-BlackItalic.otf')  format('opentype'),
  url('#{$base-path}fonts/GothamPro/GothamPro-BlackItalic.woff') format('woff'), url('#{$base-path}fonts/GothamPro/GothamPro-BlackItalic.ttf')  format('truetype'), url('#{$base-path}fonts/GothamPro/GothamPro-BlackItalic.svg#GothamPro-BlackItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GothamPro-Black';
  src: url('#{$base-path}fonts/GothamPro/GothamPro-Black.eot?#iefix') format('embedded-opentype'),  url('#{$base-path}fonts/GothamPro/GothamPro-Black.otf')  format('opentype'),
  url('#{$base-path}fonts/GothamPro/GothamPro-Black.woff') format('woff'), url('#{$base-path}fonts/GothamPro/GothamPro-Black.ttf')  format('truetype'), url('#{$base-path}fonts/GothamPro/GothamPro-Black.svg#GothamPro-Black') format('svg');
  font-weight: normal;
  font-style: normal;
}

$font-GP-bl: 'GothamPro-Black', $font-stack;
$font-GP-bl-it: 'GothamPro-BlackItalic', $font-stack;
$font-GP-bold: 'GothamPro-Bold', $font-stack;
$font-GP-bold-it: 'GothamPro-BoldItalic', $font-stack;
$font-GP-it: 'GothamPro-Italic', $font-stack;
$font-GP-lt: 'GothamPro-Ligth', $font-stack;
$font-GP-lt-it: 'GothamPro-LigthItalic', $font-stack;
$font-GP-med: 'GothamPro-Medium', $font-stack;
$font-GP-med-it: 'GothamPro-MediumItalic', $font-stack;
$font-GP-nar-bold: 'GothamPro-NarrowBold', $font-stack;
$font-GP-nar-med: 'GothamPro-NarrowMedium', $font-stack;
$font-GP-reg: 'GothamPro', $font-stack;

@font-face {
  font-family: 'icomoon';
  src:url('#{$base-path}fonts/icomoon/icomoon.eot');
  src:url('#{$base-path}fonts/icomoon/icomoon.eot#iefix') format('embedded-opentype'),
  url('#{$base-path}fonts/icomoon/icomoon.ttf') format('truetype'),
  url('#{$base-path}fonts/icomoon/icomoon.woff') format('woff'),
  url('#{$base-path}fonts/icomoon/icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-service-inner-examples:before {
  content: "\e621";
}
.icon-title-news:before {
  content: "\e622";
}
.icon-title-vote:before {
  content: "\e623";
}
.icon-service-inner-order:before {
  content: "\e624";
}
.icon-wallet:before {
  content: "\e607";
}
.icon-earphone:before {
  content: "\e608";
}
.icon-envelope:before {
  content: "\e609";
}
.icon-pdf:before {
  content: "\e60a";
}
.icon-personal:before {
  content: "\e60b";
}
.icon-logout:before {
  content: "\e60c";
}
.icon-profile:before {
  content: "\e60d";
}
.icon-research:before {
  content: "\e60e";
}
.icon-results:before {
  content: "\e60f";
}
.icon-search-len:before {
  content: "\e610";
}
.icon-service-adverticing:before {
  content: "\e611";
}
.icon-service-analytics:before {
  content: "\e612";
}
.icon-service-call-center:before {
  content: "\e613";
}
.icon-service-calc-volume:before {
  content: "\e614";
}
.icon-service-concurrent-analytics:before {
  content: "\e615";
}
.icon-service-customers-behavior:before {
  content: "\e616";
}
.icon-service-express-research:before {
  content: "\e617";
}
.icon-service-grade:before {
  content: "\e618";
}
.icon-service-inner-description:before {
  content: "\e619";
}
.icon-service-inner-pack:before {
  content: "\e61a";
}
.icon-service-inner-methods:before {
  content: "\e61b";
}
.icon-service-inner-price:before {
  content: "\e61c";
}
.icon-service-inner-clock:before {
  content: "\e61d";
}
.icon-service-inner-size-and-time:before {
  content: "\e61e";
}
.icon-service-to-marketologs:before {
  content: "\e61f";
}
.icon-title-calc:before {
  content: "\e600";
}
.icon-title-clients:before {
  content: "\e601";
}
.icon-title-employees:before {
  content: "\e602";
}
.icon-title-geography:before {
  content: "\e603";
}
.icon-title-personal:before {
  content: "\e604";
}
.icon-title-projects:before {
  content: "\e605";
}
.icon-title-service:before {
  content: "\e606";
}
.icon-web:before {
  content: "\e620";
}