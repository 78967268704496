.contacts-panel, .contacts-panel-dummy {
  background-color: $color-yellow;
  margin-bottom: 0;
  font-family: $font-fmOT3-medium;
  color: #4a4b4d;
  font-size: 14pt;
  border-radius: 0;
  width: 100%;
  height: 50px;
  bottom: 0;
  position: fixed;
  z-index: 300;
  display: none;
  padding: 10px 0;

  a {
    color: #4a4b4d;
  }

  .icon-research {
    font-size: 18px;
    color: #FFFFFF;
    margin-right: 15px;
    margin-top: 2px;
  }

  .icon-earphone {
    font-size: 18px;
    color: #FFFFFF;
    margin-right: 15px;
    margin-top: 2px;
  }

  .icon-envelope {
    font-size: 20px;
    color: #FFFFFF;
    margin-right: 15px;
    margin-top: 5px;
    position: relative;
    top: 3px;
  }

  .right {
    text-align: right;
  }
}