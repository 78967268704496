.yellow-text {
  color: $color-yellow;
}

.center {
  margin: 0 auto;
  text-align: center;
}

.text-left {
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 17pt;
  margin-bottom: 17px;
  font-family: $font-fmOT3-bold;
  color: $color-header;

  &.title {
    margin-top: 0;
  }
}

.title-row h1.title {
  font-family: $font-fmOT3-medium;
}

a {
  cursor: pointer;
  color: $color-links;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

ol {
  list-style-type: none;
  counter-reset:  item;
  padding: 0;

  li {
    &:before {
      color: $color-header;
      font-weight: bold;
      content: counter(item) ') ';
      counter-increment: item;
    }
  }
}

.text-block {
  padding: 27px 30px 45px;
  text-align: left;
  font-size: 11pt;
  line-height: 23pt;
  font-family: $font-pt-sans-caption;
}

.btn {
  border-radius: 0;
  border: 0;
  margin-right: 12px;
  padding: 8px 12px;

  &:focus {
    border: 0;
    border-radius: 0;
  }

  &:hover {
    color: #FFFFFF;
  }
}

.button-main {
  background-color: $color-yellow;
  color: #FFFFFF;
}

.button-additional {
  background-color: #938169;
  color: #FFFFFF;
}

label {
  font-family: $font-fmOT3-regular;
}

input {
  border-radius: 0;
}

form {
  .errors {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
    text-shadow: 0 1px 0 rgba(255,255,255,.2);
    -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.25),0 1px 2px rgba(0,0,0,.05);
    box-shadow: inset 0 1px 0 rgba(255,255,255,.25),0 1px 2px rgba(0,0,0,.05);
    background-image: -webkit-linear-gradient(top,#f2dede 0,#e7c3c3 100%);
    background-image: -o-linear-gradient(top,#f2dede 0,#e7c3c3 100%);
    background-image: -webkit-gradient(linear,left top,left bottom,from(#f2dede),to(#e7c3c3));
    background-image: linear-gradient(to bottom,#f2dede 0,#e7c3c3 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff2dede', endColorstr='#ffe7c3c3', GradientType=0);
    background-repeat: repeat-x;
    border-color: #dca7a7;
    border-radius: 0px;
    margin: 10px 0;
    padding-top: 7px;
    padding-bottom: 7px;
  }
}

.alert {
  border-radius: 0px;
  margin: 10px 0;
  padding-top: 7px;
  padding-bottom: 7px;
}

.jq-radio {
  margin-right: 12px;
  height: 8px;
  width: 8px;
  background: #FFFFFF url("#{$base-path}img/unchecked-circle.png") no-repeat;
  border: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  &.checked .jq-radio__div {
    background: $color-yellow url("#{$base-path}img/unchecked-circle.png") no-repeat;
    margin: 0;
    border: 0;
    height: 8px;
    width: 8px;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}
.jq-checkbox {
  margin-right: 10px;
  height: 8px;
  width: 8px;
  background: #FFFFFF url("#{$base-path}img/checkbox-unchecked.png") no-repeat;
  border: 0;
  border-radius: 0;
  box-shadow: none;

  &.checked {
    background: #FFC432 url("#{$base-path}img/checkbox-unchecked.png") no-repeat;
    height: 8px;
    width: 8px;

    & .jq-checkbox__div {
      background: none;
      box-shadow: none;
    }
  }
}

.common-block {
  background-color: #FFFFFF;
  margin-bottom: 17px;

  .white-block & {
    background-color: #F0F0F0;
  }

  .section-title {
    font-size: 2em;
    margin-bottom: 17px;
    font-family: $font-fmOT3-bold;
    color: $color-header;

    a {
      text-decoration: none;
      color: inherit;

      &:hover {
        text-decoration: none;
        color: inherit;
      }
    }
  }

  .section-text {
    text-align: left;
    font-family: $font-pt-sans-caption;
    color: $color-brown;

    .section-text-col {
      padding-left: 10px;
      vertical-align: top;
      padding-right: 45px;
    }

    .section-icon-col {
      vertical-align: top;
      font-size: 50px;

      .section-icon {
        margin-top: -10px;
      }
    }

    .title {
      font-size: 1.5em;
      margin-bottom: 17px;
      font-family: $font-fmOT3-bold;
      color: $color-header;

      a {
        text-decoration: none;
        color: inherit;

        &:hover {
          text-decoration: none;
          color: inherit;
        }
      }
    }

    .body {
      margin: 21px 0;
      font-size: 11pt;
      line-height: 23pt;

      ul {
        list-style: none;
        padding-left: 0;
      }
    }
  }

  .category-row {
    margin-left: 50px;
    margin-bottom: 19px;

    .section-text-col {
      padding-top: 15px;
    }
  }
}

.top-yellow {
  border-top: 5px solid $color-yellow;
  border-bottom: 2px solid #ededed;
  border-left: 1px solid #fcfcfc;
  border-right: 1px solid #fcfcfc;
  @extend .common-block
}

.additional-links-row {
  .additional-link {
    margin-right: 20px;
    font-family: $font-fmOT3-medium;
    font-size: 14px;

    .icon {
      margin-right: 10px;
    }

    a {
      text-decoration: none;
    }
  }
}

.title-row {
  margin-bottom: 48px;
  color: $color-brown;

  .title-icon {
    height: 76px;
    width: 60px;
    margin: 0 auto;

    .bordered {
      height: 100%;
      padding: 10px 0px;

      .icon {
        //font-weight: bold;
        font-size: 50px;
      }
    }

  }

  .title {
    margin-top: 29px;
    text-transform: uppercase;
    font-size: 1.2em;

    a, a:hover {
      color: inherit;
      text-decoration: none;
    }
  }
}

.specialist {
  margin-top: 28px;
  color: $color-brown;

  .full-name {
    font-family: $font-fmOT3-bold;
    font-size: 14pt;
    line-height: 19pt;
    color: $color-header;
  }

  .job-position {
    font-size: 10pt;
    line-height: 21pt;
  }
}

.top-yellow.news-elem {
  cursor: pointer;
}

@media only screen and (max-width:800px) {

  .mobile {
    .pop-up-callbackform-wrapper {
      font-size: 20px;
      color: white;
    }
  }
}