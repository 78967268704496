.quick-menu {
  border-collapse:separate;
  border-spacing: 0 5px;
  font-family: $font-fmOT3-bold;
  font-size: 1.25em;
  z-index: 10000;

  a {
    cursor: pointer;
    color: #FFFFFF;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &.dark .menu-item .item-text a {
    color: #4a4b4d;
  }

  .menu-item {
    height: 55px;
  }

  .item-icon {
    float: right;
    height: 55px;
    width: 55px;
    background-color: #57585a;
    padding: 7px;
    text-align: center;

    a {
      text-decoration: none;
    }

    .icon {
      font-size: 34px;
    }
  }

  .item-text {
    text-align: right;
    padding: 0 15px;

  }
}

.right-quick-menu {
  position: fixed;
  right: 0;
  top: 150px;
}


.left-quick-menu {
  position: fixed;
  left: 0;
  top: 150px;
  border-spacing: 0 0;

  .menu-item {
    height: 48px;
  }

  .item-icon {
    float: right;
    height: 44px;
    width: 48px;
    background-color: #fbf9fc;
    padding: 3px;
    text-align: center;
  }
}